import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
import { DatePicker } from '@agconnections/grow-ui';
import { useHistory, useLocation } from 'react-router-dom';

import ChevronDownIcon from 'assets/chevron_down.svg';
import ProductDetailInvoiceTable from '../ProductDetailInvoiceTable';
import ProductDetailTasksTable from '../ProductDetailTasksTable';
import ProductDetailCropSeasonSelect from '../ProductDetailCropSeasonSelect';

export const PRODUCT_DETAIL_TABLES = {
  invoices: ProductDetailInvoiceTable,
  completedTasks: ProductDetailTasksTable,
  ready_tasks: () => <h1>Ready Tasks</h1>
};

const selectOptions = [
  { key: 'invoices', value: 'Invoices' },
  { key: 'completedTasks', value: 'Completed Tasks' }
];

const ProductDetailsTableHeader = ({
  cropSeasons,
  onChangeCropSeasons,
  onChangeDateRange,
  dateRange
}) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const selectValue = useMemo(() => {
    const option = selectOptions.find(
      ({ key }) => key === queryParams.get('table')
    );
    return option?.value ?? 'Invoices';
  }, [queryParams]);

  const onChangeViewMode = useCallback(
    (_, option) => {
      const params = new URLSearchParams({ table: option.key });
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    },
    [history, location]
  );

  return (
    <section className="flex justify-between mb-4">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-semibold ml-4">Production Records</h2>
        <div className="w-52">
          <Select
            style={{ width: 180 }}
            name="table"
            value={selectValue}
            onChange={onChangeViewMode}
            suffixIcon={
              <img
                width={18}
                height={18}
                alt="chevron down"
                src={ChevronDownIcon}
              />
            }
          >
            {selectOptions.map(({ key, value }) => (
              <Select.Option key={key} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="w-82">
          <ProductDetailCropSeasonSelect
            onChange={onChangeCropSeasons}
            values={cropSeasons}
          />
        </div>
        <DatePicker
          dates={dateRange ?? undefined}
          onChange={e => onChangeDateRange(e.target.value)}
          isRangePicker
        />
      </div>
    </section>
  );
};

ProductDetailsTableHeader.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeCropSeasons: PropTypes.func.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.any),
  onChangeDateRange: PropTypes.func.isRequired
};

ProductDetailsTableHeader.defaultProps = {
  dateRange: null
};

export default ProductDetailsTableHeader;
