import React, { useMemo, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Table } from 'syngenta-digital-cropwise-react-ui-kit';

import ViewIcon from 'assets/open_in_new.svg';
import tableSort, { getHeaderSortClassName } from 'helpers/tableSort';
import useTablePaginationAndSort from 'hooks/useTablePaginationAndSort';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { paths } from 'routes/paths';
import useProductApplicationData from 'screens/ProductList/ProductDetails/hooks/useProductApplicationData';
import routesForPPLToCreate from 'screens/ProductList/helpers/creationRoutes';
import ProductDetailTableEmptyState from '../ProductDetailTableEmptyState';

const PAGE_SIZE = 20;

const showTableTotal = (totalRows, range, totalAppliedQuantity) => (
  <Row>
    <div
      style={{ bottom: 2 }}
      className="bg-banner-alert-blue-10 rounded py-1 px-3 relative"
      data-testid="product-detail-total-value"
    >
      <span>Total Applied Quantity: </span>
      <span className="font-semibold">{totalAppliedQuantity}</span>
    </div>
    <span className="ml-4 text-neutral-60">
      {range?.[0]}-{range?.[1]} of {totalRows}
    </span>
  </Row>
);

const ProductDetailTasksTable = ({ cropSeasons, dateRange }) => {
  const [totalProduct, setTotalProduct] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const { id: productId } = useParams();
  const history = useHistory();
  const {
    getProductApplications,
    tasks,
    isLoading
  } = useProductApplicationData(productId);

  const {
    reactUiKitTableOnChange,
    reactUiKitTablePaginationProp,
    sortBy,
    sortDir,
    setTotalItemsCount
  } = useTablePaginationAndSort({
    defaultSortBy: 'name',
    defaultSortDir: 'ascend',
    pageSize: PAGE_SIZE,
    pagination: {
      onChange: setPageNo,
      showTotal: (total, range) => showTableTotal(total, range, totalProduct)
    }
  });

  const tableColumns = [
    {
      title: 'Task Name',
      dataIndex: 'name',
      key: 'name',
      className: getHeaderSortClassName('name', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'name' && sortDir,
      render: fieldValue => <div>{fieldValue}</div>
    },
    {
      title: 'Completed Date',
      dataIndex: 'completedDate',
      key: 'completedDate',
      className: getHeaderSortClassName('completedDate', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'completedDate' && sortDir,
      render: fieldValue => (
        <div>{new Date(fieldValue).toLocaleDateString()}</div>
      )
    },
    {
      title: 'Rate',
      dataIndex: 'ratePerArea',
      key: 'ratePerArea',
      className: getHeaderSortClassName('ratePerArea', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'ratePerArea' && sortDir,
      render: (fieldValue, record) => (
        <div>
          {`${fieldValue.toFixed(2)} ${getAbbreviatedUnit(
            record.ratePerAreaUnit
          )}/${getAbbreviatedUnit(record.appliedAreaUnit)}`}
        </div>
      )
    },
    {
      title: 'Applied Area',
      dataIndex: 'appliedArea',
      key: 'appliedArea',
      className: getHeaderSortClassName('appliedArea', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'appliedArea' && sortDir,
      render: (fieldValue, record) => (
        <div>{`${fieldValue.toFixed(2)} ${getAbbreviatedUnit(
          record.appliedAreaUnit
        )}`}</div>
      )
    },
    {
      title: 'Applied Quantity',
      dataIndex: 'totalProduct',
      key: 'totalProduct',
      className: getHeaderSortClassName('totalProduct', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'totalProduct' && sortDir,
      render: (fieldValue, record) => (
        <div>{`${fieldValue.toFixed(2)} ${getAbbreviatedUnit(
          record.totalProductUnit
        )}`}</div>
      )
    },
    {
      title: '',
      dataIndex: 'taskId',
      key: 'taskId',
      className: getHeaderSortClassName('taskId', sortBy, sortDir),
      render: (fieldValue, record) => (
        <div className="w-full flex flex-row justify-end">
          <Link
            to={`${paths.tasks}/${record.id}/view`}
            className="flex flex-row"
          >
            <img className="mr-1" src={ViewIcon} alt="view icon" />
            <span>View</span>
          </Link>
        </div>
      )
    }
  ];

  const mappedTasks = useMemo(
    () =>
      tasks.map(task => ({
        id: task.applicationId,
        name: task.applicationName,
        completedDate: new Date(task.completedDateTime),
        taskCropSeasons: task.cropSeasonIds,
        ratePerArea: task.ratePerAreaValue,
        ratePerAreaUnit: task.ratePerAreaUnit,
        appliedArea: task.appliedAreaValue,
        appliedAreaUnit: task.appliedAreaUnit,
        totalProduct: task.productQuantityValue,
        totalProductUnit: task.productQuantityUnit
      })),
    [tasks]
  );

  const filteredAndSortedTasks = useMemo(() => {
    const filtered = mappedTasks.filter(row => {
      const taskDate = new Date(row.completedDate);
      const cropFilter =
        !cropSeasons.length ||
        row.taskCropSeasons?.some(crop => cropSeasons.includes(crop.id));
      const dateFilter =
        !dateRange ||
        (taskDate > new Date(dateRange[0]) &&
          taskDate < new Date(dateRange[1]));
      return cropFilter && dateFilter;
    });

    return tableSort(filtered, sortBy, sortDir);
  }, [mappedTasks, sortBy, sortDir, cropSeasons, dateRange]);

  useEffect(() => {
    const total = filteredAndSortedTasks.reduce((acc, task) => {
      return acc + task.totalProduct;
    }, 0);

    setTotalProduct(
      `${total.toFixed(2)} ${getAbbreviatedUnit(
        filteredAndSortedTasks[0]?.totalProductUnit
      ) ?? ''}`
    );
  }, [filteredAndSortedTasks]);

  const paginatedData = filteredAndSortedTasks.slice(
    pageNo * PAGE_SIZE,
    (pageNo + 1) * PAGE_SIZE
  );

  const isEmpty = paginatedData.length === 0;

  useEffect(() => {
    getProductApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    setTotalItemsCount(filteredAndSortedTasks.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAndSortedTasks]);

  return (
    <div className="flex" style={{ height: 'calc(100% - 3rem)' }}>
      <div className="flex-1 overflow-scroll">
        {!isEmpty && (
          <Table
            data-testid="loads-table"
            rowKey="id"
            columns={tableColumns}
            dataSource={paginatedData}
            pagination={reactUiKitTablePaginationProp}
            onChange={reactUiKitTableOnChange}
            tableLayout="auto"
          />
        )}
        {!isLoading && isEmpty && (
          <ProductDetailTableEmptyState
            type="completed_tasks"
            totalItemCount={mappedTasks.length}
            onButtonClick={() => history.push(routesForPPLToCreate.task)}
          />
        )}
      </div>
    </div>
  );
};

ProductDetailTasksTable.defaultProps = {
  dateRange: null
};

ProductDetailTasksTable.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.any)
};

export default ProductDetailTasksTable;
