import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Popup } from '@agconnections/grow-ui';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { AddRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import EditOutlined from 'assets/edit_outlined.svg';
import BulkActions from 'assets/bulk_actions.svg';
import { CropZoneIcon, FarmIcon, FieldsIcon } from 'components/Icons';
import { Context } from 'components/Store';
import {
  IS_MASS_ASSIGNER_ACTIVE,
  IS_EXPORT_PDF_MAPS_ACTIVE,
  SELECT_PROPERTY
} from 'reducers/reducer';
import { paths } from 'routes/paths';
import PropertyLandingContext from 'screens/Property/PropertiesLanding/context';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Actions = ({ setOpenFarmModal, openCreateModal, setView }) => {
  const history = useHistory();
  const { propertyLandingPageData, isFarmSelected } = useContext(
    PropertyLandingContext
  );
  const [
    {
      selectedProperty,
      selectedFieldRowId,
      organization: { id: orgId }
    },
    dispatch
  ] = useContext(Context);

  const { releaseFePropertyLandingBulkActionsByOrg } = useFlags();

  const getFieldId = () => {
    if (selectedProperty?.type === 'Field') return selectedProperty.id;
    return selectedFieldRowId.id;
  };

  const handleMassAssigner = () => {
    if (setView) {
      setView('map');
    }
    dispatch({
      type: SELECT_PROPERTY,
      payload: {}
    });
    dispatch({
      type: IS_MASS_ASSIGNER_ACTIVE,
      payload: true
    });
  };

  const handleExportPDFMaps = () => {
    // Implement view change to Export PDF Maps flow
    if (setView) {
      setView('map');
    }
    dispatch({
      type: SELECT_PROPERTY,
      payload: {}
    });
    dispatch({
      type: IS_EXPORT_PDF_MAPS_ACTIVE,
      payload: true
    });
  };

  const getParentPage = () => {
    if (isFarmSelected) return selectedProperty?.parentPage;
    return (propertyLandingPageData?.properties || []).find(
      property => property.id === selectedProperty?.farmId
    )?.parentPage;
  };

  const isBulkActionsEnabled = releaseFePropertyLandingBulkActionsByOrg?.includes(
    orgId
  );

  return (
    <div className="flex justify-between" data-testid="properties-actions">
      {isBulkActionsEnabled ? (
        <Popup
          containerClasses="popup-width mr-2"
          position="top"
          trigger={
            <Button
              style={{ width: '149px', padding: '0 22px' }}
              icon={<img width={18} src={BulkActions} alt="edit" />}
              type={ButtonType.outline}
            >
              Bulk Actions
            </Button>
          }
        >
          <Menu>
            <Menu.Item onClick={handleMassAssigner} selectable>
              <span className="ml-2">Bulk Edit</span>
            </Menu.Item>
            <Menu.Item onClick={handleExportPDFMaps} selectable>
              <span className="ml-2">Export PDF Maps</span>
            </Menu.Item>
          </Menu>
        </Popup>
      ) : (
        <Button
          style={{ width: '149px', padding: '0 32px' }}
          icon={<img width={18} src={EditOutlined} alt="edit" />}
          type={ButtonType.outline}
          onClick={handleMassAssigner}
        >
          Bulk Edit
        </Button>
      )}

      <Popup
        containerClasses="popup-width"
        position="top"
        trigger={
          <Button
            style={{ width: '149px' }}
            icon={<AddRounded />}
            type={ButtonType.success}
            ghost
          >
            New Property
          </Button>
        }
      >
        <Menu>
          <Menu.Item onClick={() => setOpenFarmModal(true)} selectable>
            <FarmIcon />
            <span className="ml-2">Farm</span>
          </Menu.Item>
          <Menu.Item onClick={() => openCreateModal(true)} selectable>
            <FieldsIcon />
            <span className="ml-2">Field</span>
          </Menu.Item>
          {(selectedProperty?.type === 'Field' ||
            selectedProperty?.type === 'Crop Zone') && (
            <Menu.Item
              onClick={() => {
                history.push(
                  `${paths.properties}/create/${getFieldId()}/cropzone`,
                  {
                    parentPage: getParentPage()
                  }
                );
              }}
              selectable
            >
              <CropZoneIcon />
              <span className="ml-2">Crop Zone</span>
            </Menu.Item>
          )}
        </Menu>
      </Popup>
    </div>
  );
};

Actions.propTypes = {
  openCreateModal: PropTypes.func.isRequired,
  setOpenFarmModal: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired
};

export default Actions;
