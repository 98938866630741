// Draw Styles
export const drawInactiveColor = '#fff';
export const drawActiveColor = '#73DB78';
export const drawFillOpacity = 0.3;

// Coordinates for the center of the US
export const USA_CENTER_COORDINATES = [-98.5795, 39.8283];

export const DEFAULT_ZOOM = [16];
export const NATIONAL_ZOOM = [4];
export const MAX_ZOOM = 20;
export const MIN_ZOOM = 1;

export const DEFAULT_FLY_TO_OPTIONS = {
  speed: 1.8,
  maxDuration: 1
};

export const UPDATE_TYPES = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete'
};

export const KEY_CODES = {
  BACKSPACE: 8,
  DELETE: 46,
  ESC: 27
};

export const LAND_TYPES = {
  FARM: 'farm',
  FIELD: 'field',
  CROP_ZONE: 'cropzone'
};

export const PLURAL_LAND_TYPES = {
  FIELD: 'fields',
  CROP_ZONE: 'cropzones'
};

export const LAND_TYPE_DEPTHS = [LAND_TYPES.FIELD, LAND_TYPES.CROP_ZONE];

export const MAP_HISTORY_FEATURE_TYPES = {
  IMPORT: 'import_feature',
  ADD: 'add_feature',
  REMOVE: 'remove_feature'
};

export const propertyOwnerOptions = [];

export const stateOptions = [
  {
    key: 'TN',
    value: 'Tennessee'
  },
  { key: 'KY', value: 'Kentucky' }
];

export const countyOptions = [
  { key: 'Davidson', value: 'Davidson County', parent: 'TN' },
  { key: 'Henry', value: 'Henry County', parent: 'TN' },
  { key: 'Benton', value: 'Benton County', parent: 'KY' },
  { key: 'Calloway', value: 'Calloway County', parent: 'KY' },
  { key: 'Graves', value: 'Graves County', parent: 'KY' },
  { key: 'Marshall', value: 'Marshall County', parent: 'KY' }
];

export const tileDrainageOptions = [
  { key: 'noTileDrain', value: 'No Tile Drain' },
  { key: 'standardDensity', value: 'Standard Density' },
  {
    key: 'standardDensityWithDrainageWaterManagement',
    value: 'Standard Density With Drainage Water Management'
  },
  { key: 'highDensity', value: 'High Density' },
  {
    key: 'highDensityWithDrainageWaterManagement',
    value: 'High Density With Drainage Water Management'
  }
];

export const rentTypeOptions = [
  { key: 'cashRent', value: 'Cash Rent' },
  { key: 'cropShare', value: 'Crop Share' },
  { key: 'other', value: 'Other' }
];

export const bearingOptions = [
  { key: 'E', value: 'East' },
  { key: 'N', value: 'North' },
  { key: 'S', value: 'South' },
  { key: 'W', value: 'West' }
];

export const townshipOptions = [
  { key: '', value: 'None' },
  { key: 'N', value: 'North' },
  { key: 'S', value: 'South' }
];

export const rangeOptions = [
  { key: '', value: 'None' },
  { key: 'E', value: 'East' },
  { key: 'W', value: 'West' }
];

export const waterSourceOptions = [
  { key: 'irrigationDistrict', value: 'Irrigation District' },
  { key: 'municipal', value: 'Municipal' },
  { key: 'onFarmPond', value: 'On Farm Pond' },
  { key: 'reservoir', value: 'Reservoir' },
  { key: 'river', value: 'River' },
  { key: 'stream', value: 'Stream' },
  { key: 'tailwater', value: 'Tailwater' },
  { key: 'well', value: 'Well' }
];

export const waterOriginOptions = [
  { key: 'acquifer', value: 'Acquifer' },
  { key: 'watershed', value: 'Watershed' }
];

export const pumpEnergySourceOptions = [
  { key: 'diesel', value: 'Diesel' },
  { key: 'electric', value: 'Electric' },
  { key: 'naturalGas', value: 'Natural Gas' },
  { key: 'propane', value: 'Propane' }
];

export const irrigationSystemOptions = [
  { key: 'basin', value: 'Basin' },
  { key: 'border', value: 'Border' },
  { key: 'drip', value: 'Drip' },
  { key: 'flood', value: 'Flood' },
  { key: 'furrow', value: 'Furrow' },
  { key: 'linear', value: 'Linear' },
  { key: 'microSprinkler', value: 'Micro Sprinkler' },
  { key: 'pivot', value: 'Pivot' },
  { key: 'subsurface', value: 'Subsurface' },
  { key: 'travelingGun', value: 'Traveling Gun' },
  { key: 'wheelLines', value: 'Wheel Lines' }
];

export const cropYearOptions = [];

export const tillageOptions = [
  { key: 'intensive', value: 'Intensive' },
  { key: 'conventional', value: 'Conventional' },
  { key: 'reduced', value: 'Reduced' },
  { key: 'no-till', value: 'No-Till' }
];

export const soilTextureOptions = [
  { key: 'clay', value: 'Clay' },
  { key: 'silt', value: 'Silt' },
  { key: 'sand', value: 'Sand' },
  { key: 'loam', value: 'Loam' },
  { key: 'siltyClay', value: 'Silty Clay' },
  { key: 'sandyClayLoam', value: 'Sandy Clay Loam' },
  { key: 'siltyClayLoam', value: 'Silty Clay Loam' },
  { key: 'clayLoam', value: 'Clay Loam' },
  { key: 'siltyLoam', value: 'Silty Loam' },
  { key: 'sandyLoam', value: 'Sandy Loam' },
  { key: 'loamySand', value: 'Loamy Sand' }
];

export const cropOptions = [
  { key: 'Alfalfa', value: 'Alfalfa' },
  { key: 'Barley : Feed', value: 'Barley : Feed' },
  { key: 'Blackberry', value: 'Blackberry' },
  { key: 'Chrysanthemum', value: 'Chrysanthemum' },
  { key: 'Clover : Arrowleaf', value: 'Clover : Arrowleaf' },
  { key: 'Cotton : Conv', value: 'Cotton : Conv' },
  { key: 'Edamame', value: 'Edamame' },
  { key: 'Fruit Trees : Apple', value: 'Fruit Trees : Apple' },
  { key: 'Grass Seed Mixed', value: 'Grass Seed Mixed' },
  { key: 'Hay : Oat Hay', value: 'Hay : Oat Hay' },
  { key: 'Melon : Muskmelon', value: 'Melon : Muskmelon' },
  { key: 'Onions', value: 'Onions' },
  { key: 'Potato : Dehydrated', value: 'Potato : Dehydrated' },
  { key: 'Squash', value: 'Squash' },
  { key: 'Sunflower', value: 'Sunflower' },
  { key: 'Turnip Greens', value: 'Turnip Greens' }
];

export const TABLE_COLUMNS = [
  {
    field: 'property',
    title: 'Property',
    level: '1',
    sortable: true
  },
  {
    field: 'acres',
    title: 'Acres',
    level: '0',
    sortable: true
  },
  {
    field: 'crops',
    title: 'Crops',
    level: '0',
    sortable: true
  },
  {
    field: 'cropSeason',
    title: 'Crop Season',
    sortable: true
  },
  {
    field: 'costPerAc',
    title: 'Cost/Ac',
    level: '0',
    sortable: true
  },
  {
    field: 'revenuePerAc',
    title: 'Revenue/Ac',
    level: '0',
    sortable: true
  }
];

export const PROPERTY_TYPE_ORGANIZATION = 'organization';
export const PROPERTY_TYPE_PROPERTY = 'property';
export const PROPERTY_TYPE_FIELD = 'field';
export const PROPERTY_TYPE_CROP_ZONE = 'cropZone';
export const PROPERTY_TYPES = [
  PROPERTY_TYPE_ORGANIZATION,
  PROPERTY_TYPE_PROPERTY,
  PROPERTY_TYPE_FIELD,
  PROPERTY_TYPE_CROP_ZONE
];

export const LAST_COORDINATES_STORED = 'lastCoordinatesStored';

export const PROPERTY_VIEW_MODE = {
  MAP: 'map',
  INFO: 'info',
  COST: 'costAnalysis'
};
