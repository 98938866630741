import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { paths } from 'routes/paths';
import { FETCH_TYPES } from 'helpers/constants';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { Context } from 'components/Store';
import createAction from 'helpers/createAction';
import { SELECT_PROPERTY, SELECTED_FIELD_ROW_ID } from 'reducers/reducer';
import { ROLES } from 'utilities/access';
import { PROPERTY_VIEW_MODE } from 'screens/Property/helpers/constants';
import shouldResetViewMode from './helpers/shouldResetViewMode';
import filterFeatureCollectionByParentIdNoZoom from './helpers/filterFeatureCollectionByParentIdNoZoom';

const initialState = {};
const PropertyLandingContext = createContext(initialState);

export default PropertyLandingContext;

const propertyViewStorageApi = getLocalStorageApi(
  STORAGE_KEYS.PROPERTY_VIEW_V2,
  PROPERTY_VIEW_MODE.MAP
);

export function PropertyLandingProvider({ children }) {
  const amplitude = useContext(AmplitudeContext);
  const history = useHistory();
  const flags = useFlags();

  const [
    {
      organization,
      selectedProperty,
      loggedInUserOrgPermission,
      searchTextValueFFT
    },
    dispatch
  ] = useContext(Context);

  const { propertyCount } = organization?.properties || {};
  const isHugeOrganization =
    flags.configureLargeGrowerConditionals?.propertiesThreshold &&
    propertyCount >= flags.configureLargeGrowerConditionals.propertiesThreshold;

  const [params, setParams] = useState({ page: 0 });
  const [selectedField, setSelectedField] = useState();
  const [financialAccess, setFinancialAccess] = useState();
  const [viewMode, setViewMode] = useState(propertyViewStorageApi.get());

  const {
    loading: isLoadingProperties,
    zoom: propertyZoom,
    setZoom: onPropertySetZoom,
    dataSorted: propertyLandingPageData,
    reloadData,
    fieldsAndAreasGeoJSONCollection,
    setGeoJSONCollectionFeatures,
    fieldsLoading: isLoadingFields,
    filterFeatureCollectionByParentId,
    fetchFarmCosts,
    isLoadingPaginatedProperties,
    pagination
  } = usePopulateMapData({
    fetchType: isHugeOrganization
      ? FETCH_TYPES.paginatedPropertiesMap
      : FETCH_TYPES.propertiesMap,
    isCacheOn: false
  });

  const isCostAnalysisViewEnabled = featureFlagRenderItem(
    flags.releaseCostAnalysisViewEnable,
    flags.releaseCostAnalysisViewEnableByOrg,
    organization.id
  );

  const isPropertyDetailsEnabled = featureFlagRenderItem(
    flags.fePropertyDetails,
    flags.fePropertyDetailsByOrgId,
    organization?.id
  );

  const onViewModeChange = useCallback(newView => {
    setViewMode(newView);
    propertyViewStorageApi.save(newView);
  }, []);

  const onDrawFieldsClick = (currentSelectedProperty, filteredProperties) => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.createField
    );

    let selectedId = null;
    if (currentSelectedProperty.type === 'Farm') {
      selectedId = currentSelectedProperty.id;
    } else if (currentSelectedProperty.type === 'Field') {
      const filterField = filteredProperties
        ?.filter(property =>
          property?.fields?.find(
            field => field.id === currentSelectedProperty.id
          )
        )
        .pop();
      selectedId = filterField.id;
    }

    history.push(`${paths.properties}/create`, {
      selectedId
    });
  };

  const selectProperty = useCallback((property, type) => {
    createAction(dispatch, SELECT_PROPERTY, {
      ...property,
      type
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPropertySelection = useCallback(
    selected => {
      if (!selected || selected?.id === selectedProperty?.id) {
        setSelectedField(null);
        selectProperty();
        filterFeatureCollectionByParentId(fieldsAndAreasGeoJSONCollection);
        return;
      }

      let type = 'Crop Zone';
      if ('fields' in selected) {
        type = 'Farm';
        setSelectedField(null);
        fetchFarmCosts(selected.id);
      }

      if ('cropzones' in selected) {
        type = 'Field';
        setSelectedField(selected.id);
        createAction(dispatch, SELECTED_FIELD_ROW_ID, { id: selected.id });
      }

      if (selected?.$parentFieldId) {
        createAction(dispatch, SELECTED_FIELD_ROW_ID, {
          id: selected.$parentFieldId
        });
      }

      selectProperty(selected, type);
      setGeoJSONCollectionFeatures(
        selected?.isFromMap
          ? filterFeatureCollectionByParentIdNoZoom(
              fieldsAndAreasGeoJSONCollection,
              selected.id
            )
          : filterFeatureCollectionByParentId(
              fieldsAndAreasGeoJSONCollection,
              selected.id
            )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      selectedProperty,
      setGeoJSONCollectionFeatures,
      fieldsAndAreasGeoJSONCollection.features,
      filterFeatureCollectionByParentId
    ]
  );

  useEffect(() => {
    if (
      (loggedInUserOrgPermission?.role === ROLES.FULL_CONTROL ||
        loggedInUserOrgPermission?.role === ROLES.VIEW_ONLY) &&
      !Object.prototype.hasOwnProperty.call(
        loggedInUserOrgPermission,
        'financialAccess'
      )
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(loggedInUserOrgPermission?.financialAccess);
    }
  }, [loggedInUserOrgPermission]);

  useEffect(() => {
    setParams({ page: 0, textSearch: searchTextValueFFT?.valueInput });
  }, [searchTextValueFFT]);

  useEffect(() => {
    setViewMode(propertyViewStorageApi.get());
  }, [organization]);

  useEffect(() => {
    if (
      shouldResetViewMode({
        selectedProperty,
        currentView: viewMode,
        isCostAnalysisViewEnabled
      })
    ) {
      setViewMode(PROPERTY_VIEW_MODE.MAP);
    }
  }, [selectedProperty, viewMode, isCostAnalysisViewEnabled]);

  useEffect(() => {
    if (!isHugeOrganization) return;
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Organization.paginatedProperties,
      {
        organizationId: organization?.id
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id, isHugeOrganization]);

  const isFarmSelected = selectedProperty?.type === 'Farm';
  const isFieldSelected = selectedProperty?.type === 'Field';
  const isCropZoneSelected = selectedProperty?.type === 'Crop Zone';

  return (
    <PropertyLandingContext.Provider
      value={{
        params,
        setParams,
        pagination,
        // conditions
        isCostAnalysisViewEnabled,
        isPropertyDetailsEnabled,
        isFarmSelected,
        isFieldSelected,
        isCropZoneSelected,
        isLoadingPaginatedProperties,
        isHugeOrganization,

        //
        selectedProperty,
        selectedField,
        financialAccess,
        viewMode,
        onViewModeChange,
        onDrawFieldsClick,

        // map
        isLoadingProperties,
        isLoadingFields,
        propertyZoom,
        propertyLandingPageData,
        fieldsAndAreasGeoJSONCollection,

        setGeoJSONCollectionFeatures,
        filterFeatureCollectionByParentId,
        fetchFarmCosts,
        fetchProperties: reloadData,
        onPropertySetZoom,
        onPropertySelection
      }}
    >
      {children}
    </PropertyLandingContext.Provider>
  );
}

PropertyLandingProvider.propTypes = {
  children: PropTypes.node.isRequired
};
