import React, { useCallback, useEffect, useMemo, useState } from 'react';

const DEFAULT_SORT_BY = 'name';
const DEFAULT_SORT_DIR = 'ascend';

/**
 * Custom React hook for managing pagination and sorting with Ant Design's Table component.
 *
 * @param {Object} config - Configuration object for the hook.
 * @param {number} [config.pageSize=50] - Default number of items per page.
 * @param {string} [config.defaultSortBy='name'] - Initial field to sort by.
 * @param {string} [config.defaultSortDir='ascend'] - Initial sorting direction, can be 'ascend' or 'descend'.
 *
 * @returns {Object} Returns an object containing:
 * - totalItemsCount (number): The total number of items.
 * - setTotalItemsCount (Function): Setter function to update the total number of items.
 * - page (number): Current page number.
 * - setPage (Function): Setter function to update the current page.
 * - pageSize (number): Number of items per page.
 * - setPageSize (Function): Setter function to update the number of items per page.
 * - sortBy (string): Current field used for sorting.
 * - setSortBy (Function): Setter function to update the sorting field.
 * - sortDir (string): Current sorting direction.
 * - setSortDir (Function): Setter function to update the sorting direction.
 * - reactUiKitTablePaginationProp (Object): Configuration object for Ant Design's pagination component.
 * - reactUiKitTableOnChange (Function): Handler for changes in sorting and pagination.
 */
const useTablePaginationAndSort = ({
  pageSize: defaultPageSize = 50,
  defaultSortBy = DEFAULT_SORT_BY,
  defaultSortDir = DEFAULT_SORT_DIR,
  pagination: {
    showTotal = (total, range) => (
      <span className="text-neutral-60">
        {range[0]}-{range[1]} of {total}
      </span>
    ),
    ...restPagination
  } = {}
}) => {
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [sortDir, setSortDir] = useState(defaultSortDir);

  // Note: Ant Design's Table component defaults to 1-based indexing for pagination.
  // This means that the `current` page in the pagination configuration starts at 1.
  // Here, we maintain this convention for compatibility and intuitive UI experience.
  // Adjustments to page number for zero-based scenarios should be handled externally if needed.
  const reactUiKitTablePaginationProp = useMemo(
    () => ({
      defaultCurrent: 1,
      current: page,
      defaultPageSize,
      pageSize,
      total: totalItemsCount,
      showTotal,
      showSizeChanger: false,
      position: ['bottomRight'],
      ...restPagination,
      onChange: (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);
        restPagination.onChange(newPage - 1, newPageSize);
      }
    }),
    [
      page,
      pageSize,
      totalItemsCount,
      defaultPageSize,
      showTotal,
      restPagination
    ]
  );

  const reactUiKitTableOnChange = useCallback(
    (_, __, { order, field }) => {
      if (!order) {
        setSortBy(defaultSortBy);
        setSortDir(defaultSortDir);
      } else {
        setSortBy(field);
        setSortDir(order);
      }
    },
    [defaultSortBy, defaultSortDir]
  );

  useEffect(() => {
    setPage(1);
    if (restPagination.onChange) restPagination.onChange(0, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortDir]);

  return {
    totalItemsCount,
    setTotalItemsCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortBy,
    setSortBy,
    sortDir,
    setSortDir,
    reactUiKitTablePaginationProp,
    reactUiKitTableOnChange
  };
};

export default useTablePaginationAndSort;
