import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import AddProductInvoiceModal from 'components/ProductSideBar/components/AddProductInvoiceModal';
import EllipseMenu from 'components/EllipseMenu';
import { TAB_OPTIONS } from 'utilities/menus';
import formatNumber from 'helpers/formatNumber';
import EmptyProductsDisplay from './EmptyProductsDisplay';

const InvoiceProductTable = ({
  removeProduct,
  noBlank,
  memberRole,
  memberFinancialAccess,
  setCustomProductCreated,
  customProductCreated
}) => {
  const [customProductModalOpened, setCustomProductModalOpened] = useState(
    false
  );
  const [rowIndex, setRowIndex] = useState(null);
  const { values } = useFormikContext();
  const [sort] = useState();
  const [invoiceProductItem, setInvoiceProductItem] = useState(null);

  useEffect(() => {
    if (values.invoiceProducts?.length > 0) {
      const lastIndex = values.invoiceProducts.length - 1;
      const lastProductAdded = values.invoiceProducts[lastIndex];

      if (lastProductAdded.custom && customProductCreated) {
        setInvoiceProductItem(lastProductAdded);
        setRowIndex(lastIndex);
        setCustomProductModalOpened(true);
        setCustomProductCreated(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.invoiceProducts, customProductCreated]);

  const handleSort = () => {};
  const handleAction = (action, product, index) => {
    switch (action) {
      case 'edit':
        setRowIndex(index);
        setInvoiceProductItem(product);
        setCustomProductModalOpened(true);
        break;
      case 'remove':
        removeProduct(index);
        break;
      default:
        break;
    }
  };

  const calcUnitPrice = (totalCost, totalProduct) => {
    return (
      Number(
        Number.parseFloat(totalCost) / Number.parseFloat(totalProduct)
      ).toFixed(2) || 1
    );
  };

  const closeProductModal = () => {
    setCustomProductModalOpened(false);
    setInvoiceProductItem(null);
  };

  const getAdditionalHeaders = () => {
    return (
      <>
        <Table.Cell sort={sort} onClick={handleSort}>
          Total Product
        </Table.Cell>
        <Table.Cell sort={sort} onClick={handleSort}>
          Unit Price
        </Table.Cell>
        <Table.Cell sort={sort} onClick={handleSort}>
          Total Price per Product
        </Table.Cell>
      </>
    );
  };

  const getLiveDataTable = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Cell sort={sort} onClick={handleSort}>
            {`Products (${values?.invoiceProducts?.length})`}
          </Table.Cell>
          {getAdditionalHeaders()}
          <Table.Cell />
        </Table.Header>
        {values?.invoiceProducts?.map((product, index) => {
          return (
            <Table.Row>
              <Table.Cell>
                <div className="text-left">
                  <div className="font-bold">{product?.productName}</div>
                  <div className="mt-2 text-neutral-300">
                    {product?.manufacturerName}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>
                {formatNumber(
                  product?.totalQuantityValue,
                  product?.totalQuantityUnit
                )}
              </Table.Cell>
              {memberRole === 'Full control' &&
              (memberFinancialAccess === 'none' ||
                memberFinancialAccess === 'READ') ? (
                <Table.Cell />
              ) : (
                <Table.Cell>
                  $
                  {calcUnitPrice(
                    product?.totalCostValue,
                    product?.totalQuantityValue
                  ) || '0.00'}
                </Table.Cell>
              )}
              {memberRole === 'Full control' &&
              (memberFinancialAccess === 'none' ||
                memberFinancialAccess === 'READ') ? (
                <Table.Cell />
              ) : (
                <Table.Cell>
                  <div className="flex w-28 items-center justify-around">
                    <div>${product?.totalCostValue || '0'}</div>
                  </div>
                </Table.Cell>
              )}
              <Table.Cell>
                <div data-testid="invoice-table-options-menu">
                  <EllipseMenu
                    onAction={action => handleAction(action, product, index)}
                    options={TAB_OPTIONS}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
    );
  };

  return (
    <div data-testid="invoice-product-table">
      {invoiceProductItem && (
        <AddProductInvoiceModal
          values={values}
          open={customProductModalOpened}
          product={invoiceProductItem}
          onClose={closeProductModal}
          isEdit
          rowIndex={rowIndex}
          memberRole={memberRole}
          memberFinancialAccess={memberFinancialAccess}
        />
      )}
      {!values.invoiceProducts?.length ? (
        <>
          <Table>
            <Table.Header>
              <Table.Cell sort={sort} onClick={handleSort}>
                Products(0)
              </Table.Cell>
              {getAdditionalHeaders()}
            </Table.Header>
          </Table>
          {!noBlank ? <EmptyProductsDisplay /> : <></>}
        </>
      ) : (
        getLiveDataTable()
      )}
    </div>
  );
};

InvoiceProductTable.defaultProps = {
  noBlank: false
};

InvoiceProductTable.propTypes = {
  removeProduct: PropTypes.func.isRequired,
  noBlank: PropTypes.bool,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  setCustomProductCreated: PropTypes.func.isRequired,
  customProductCreated: PropTypes.bool.isRequired
};
export default InvoiceProductTable;
